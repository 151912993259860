"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Op;
(function (Op) {
  function length(op) {
    if (typeof op.delete === 'number') {
      return op.delete;
    } else if (typeof op.retain === 'number') {
      return op.retain;
    } else if (typeof op.retain === 'object' && op.retain !== null) {
      return 1;
    } else {
      return typeof op.insert === 'string' ? op.insert.length : 1;
    }
  }
  Op.length = length;
})(Op || (Op = {}));
exports.default = Op;
